<template>
  <div class="map-dynamic-filter">
    <condition
      v-model="localCondition"
      :conditions="conditionOptions"
      @change="changeCondition"
      class="dynamic-filter-condition"
    ></condition>
    <el-date-picker
      v-model="filter.value"
      size="mini"
      :type="datetime ? 'datetime' : undefined"
      :format="datetime ? 'dd-MM-yyyy HH:mm:ss' : 'dd-MM-yyyy'"
      :value-format="datetime ? 'dd-MM-yyyy HH:mm:ss' : 'dd-MM-yyyy'"
      class="dynamic-filter-value"
      style="width: 60%; max-width: 270px;"
      :disabled="['is_null', 'is_not_null'].includes(filter.condition)"
    ></el-date-picker>
  </div>
</template>

<script>
  import Condition from './Conditions'

  export default {
    name: 'DateTimeFilter',
    components: { Condition },
    props: {
      filter: {
        type: Object,
        required: true
      },
      datetime: {
        type: Boolean,
        default: false
      }
    },
    data: function () {
      return {
        conditionOptions: ['eq', 'neq', 'gt', 'gte', 'lt', 'lte', 'is_null', 'is_not_null'],
        localCondition: null
      }
    },
    mounted () {
      this.$set(this, 'localCondition', this.filter.condition)
    },
    methods: {
      changeCondition (newVal) {
        this.$set(this.filter, 'condition', newVal)
      }
    }
  }
</script>

<style scoped>
  .el-input--suffix /deep/ .el-input__inner {
    padding-right: 5px;
  }
</style>
