<template>
  <date-filter :filter="filter" :datetime="true"></date-filter>
</template>

<script>
  import DateFilter from './DateFilter'

  export default {
    name: 'DateTimeFilter',
    components: { DateFilter },
    props: {
      filter: {
        type: Object
      }
    }
  }
</script>

<style scoped>

</style>
