<template>
  <el-select
    v-model="localValue"
    @change="$emit('change', localValue)"
    size="mini"
  >
    <el-option
      v-for="(item, index) in options"
      :key="index"
      :label="item.name"
      :value="item.value"
      ></el-option>
  </el-select>
</template>

<script>

  const CONDITIONS = {
    'eq': {
      name: 'Равно',
      value: 'eq'
    },
    'neq': {
      name: 'Не равно',
      value: 'neq'
    },
    'like': {
      name: 'Включает',
      value: 'like'
    },
    'not_like': {
      name: 'Не включает',
      value: 'not_like'
    },
    'is_null': {
      name: 'Пусто',
      value: 'is_null'
    },
    'is_not_null': {
      name: 'Не пусто',
      value: 'is_not_null'
    },
    'gt': {
      name: 'Больше',
      value: 'gt'
    },
    'gte': {
      name: 'Больше или равно',
      value: 'gte'
    },
    'lt': {
      name: 'Меньше',
      value: 'lt'
    },
    'lte': {
      name: 'Меньше или равно',
      value: 'lte'
    },
    'equals_any': {
      name: 'Содержит',
      value: 'equals_any'
    },
    'not_equals_any': {
      name: 'Не содержит',
      value: 'not_equals_any'
    },
    // for xrefs where equals not work correctly with array
    'equals_all': {
      name: 'Равно',
      value: 'equals_all'
    },
    'not_equals_all': {
      name: 'Не равно',
      value: 'not_equals_all'
    },
  }

  export default {
    name: 'conditions',
    props: {
      conditions: {
        type: Array
      },
      value: {
        type: String,
        default: null
      }
    },
    data: function () {
      return {
        options: [],
        localValue: null
      }
    },
    mounted: function () {
      this.$set(this, 'localValue', this.value)
      for (let i = this.conditions.length - 1; i >= 0; i--) {
        this.options.splice(this.options.length, 0, CONDITIONS[this.conditions[i]])
      }
    }
  }
</script>

<style scoped>
</style>
